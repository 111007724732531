import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Cognisphere,
  ContentContainer,
  Heading,
  KeyVisual,
  LineContainer,
  Link,
  Paragraph,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { siemensThemeDarkHeader } from './../themes/siemens/siemensThemeDarkHeader'
import { mq } from './../utility/breakpoint'

const IndexPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const [loaded, setLoaded] = React.useState(false)

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])
  const { t } = useTranslation('forbidden')

  const uri = () => {
    return 'https://new.siemens.com/global/en/company/fairs-events/fairs/formnext/registration-virtual.html'
  }

  return (
    <PageFrame {...params} pageName="forbidden" isTop hideMenu={true}>
      {loaded && (
        <KeyVisual extraLayer hideScrollCta>
          <Cognisphere visual="/assets/keyvisual.jpg" asImage />

          <ContentContainer fullheight>
            <StartTeaser fullheight width={50}>
              <div>
                <LineContainer className="LineContainer">
                  <Heading type="h6" spacing={{ bottom: 0.8 }}>
                    {t('topline')}
                  </Heading>

                  <Heading>{parse(t('header'))}</Heading>
                </LineContainer>
                <Paragraph>{t('copy')}</Paragraph>
                <Button
                  onClick={() => window.open(uri(), '_blank', 'noopener')}
                >
                  {t('cta.label')}
                </Button>
                <ResendTokenLinkContainer>
                  <Link href={`/${lang}/resend-token`}>
                    {t('alreadyRegistered')}
                  </Link>
                </ResendTokenLinkContainer>
              </div>
            </StartTeaser>
          </ContentContainer>
        </KeyVisual>
      )}
    </PageFrame>
  )
}

export default IndexPage

interface StartTeaserProps {
  fullheight?: boolean
  width?: number
}

const Copy2Container = styled.div({
  marginTop: '1.5rem',
})

const ResendTokenLinkContainer = styled.div({ marginTop: '2.5rem' })

const StartTeaser = styled.div<StartTeaserProps>(
  ({ theme: { grid }, fullheight, width }) => `
  height: 100%;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight &&
    `min-height:calc(100vh - 220px);
    margin-top:100px;
    ${[mq[2]]}{
      margin-top:0px;
      display:flex;
      align-items:center;
    }`
  }

  margin-left:${100 / grid.columns}vw;
  h1{
    margin:0;
  }
  p, .LineContainer{ margin-bottom:50px;}
`
)
